enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

  // Pickaso Reporting
  LOAD_CLIENTS = "loadClients",
  POST_CLIENT = "postClient",
  LOAD_UAPROJECTS = "loadUaProjects",
  POST_UAPROJECT = "postUaProject",
  POST_UAREPORT = "postUaReport",
  LOAD_ASOPROJECTS = "loadAsoProjects",
  POST_ASOPROJECT = "postAsoProject",
  POST_ASOREPORT = "postAsoReport",
  LOAD_INTEGRATIONS = "loadIntegrations",
  POST_INTEGRATION = "postIntegration",
  LOAD_THIRDPARTIES = "loadThirdParties",
  LOAD_ROLES = "loadRoles",
  LOAD_USERS = "loadUsers",
  POST_USER = "postUser"
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  
  // Pickaso Reporting
  SET_CLIENTS = "setClients",
  SET_UAPROJECTS = "setUaProjects",
  SET_ASOPROJECTS = "setAsoProjects",
  SET_INTEGRATIONS = "setIntegrations",
  POST_INTEGRATION_ERROR = "postIntegrationError",
  SET_THIRDPARTIES = "setThirdParties",
  SET_ROLES = "setRoles",
  SET_USERS = "setUsers",

  SET_SELECTED_CLIENT = "setSelectedClient"
      
  
}

export { Actions, Mutations };
