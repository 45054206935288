import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {reactive, ref} from "vue";

export interface Client {
  id: number;
  name: string;
}

export interface AsoProject {
  id: number;
  client_id: number|null;
  name: string;
  os: string;
  app: string;
  countries: string;
  console_id: number|null;
  // console_account: string;
  appbot_id: number|null;
  apptweak_id: number|null;
  aso_spreadsheet: string;
  console_spreadsheet: string;
  client: Client;
  console: Integration;
  appbot: Integration;
  apptweak: Integration;
}

export interface UaProject {
  id: number;
  name: string;
  client_id: number|null;
  tracker_id: number|null;
  dashboard: number;
  spreadsheet: string;
  client: Client;
}

export interface Integration {
  id: number;
  thirdparty_id: number|null;
  user: string;
  password: string;
  status: string;
  two_factor_code: string;
  error: string;
  thirdparty: Thirdparty,
  data: Record<string, unknown>,
  cookie: string | ArrayBuffer | null
}

export interface Thirdparty {
  id: number;
  name: string;
}

export interface Os {
  id: string;
  name: string;
}

export interface User {
  id: number;
  name: string;
  email: string;
  password: string;
  token: string;
  role: string;
}

export interface Role {
  id: string;
  name: string;
}

export interface ReportingData {
  clients: Array<Client>;
  asoProjects: Array<AsoProject>;
  uaProjects: Array<UaProject>;
  integrations: Array<Integration>;
  thirdParties: Array<Thirdparty>;
  roles: Array<Role>;
  users: Array<User>;
}

@Module
// export default class ApiModule extends VuexModule implements ReportingData {
export default class ApiModule extends VuexModule  {

  clients = [];
  asoProjects = [];
  uaProjects = [];
  integrations = [];
  thirdParties = [];
  roles = [];
  users = [];
  clientsNumber = 1;

  selectedClient = null;

  /**
   * Get clients
   * @returns array
   */
  get getClients() {
    return this.clients;
  }

  /**
   * Get ASO Projects
   * @returns array
   */
  get getAsoProjects(): Array<AsoProject> {
    return this.asoProjects;
  }

  /**
   * Get UA Projects
   * @returns array
   */
  get getUaProjects(): Array<UaProject> {
    return this.uaProjects;
  }

  /**
   * Get Integrations
   * @returns array
   */
  get getIntegrations(): Array<Integration> {
    return this.integrations;
  }

  /**
   * Get Thirdparties
   * @returns array
   */
  get getThirdParties(): Array<Thirdparty> {
    return this.thirdParties;
  }

  /**
   * Get roles
   * @returns array
   */
  get getRoles(): Array<Thirdparty> {
    return this.roles;
  }

  /**
   * Get database users
   * @returns array
   */
  get getUsers(): Array<User> {
    return this.users;
  }

  @Mutation
  [Mutations.SET_CLIENTS](clients) {
    this.clientsNumber = clients.length;
    this.clients = clients;
  }

  [Mutations.SET_SELECTED_CLIENT](selectedClient) {
    this.selectedClient = selectedClient;
  }

  @Mutation
  [Mutations.SET_ASOPROJECTS](asoProjects) {
    this.asoProjects = asoProjects;
  }

  @Mutation
  [Mutations.SET_UAPROJECTS](uaProjects) {
    this.uaProjects = uaProjects;
  }

  @Mutation
  [Mutations.SET_INTEGRATIONS](integrations) {
    this.integrations = integrations;
  }

  @Mutation
  [Mutations.SET_THIRDPARTIES](thirdParties) {
    this.thirdParties = thirdParties;
  }

  @Mutation
  [Mutations.SET_ROLES](roles) {
    this.roles = roles;
  }

  @Mutation
  [Mutations.SET_USERS](users) {
    this.users = users;
  }

  @Action
  [Actions.LOAD_CLIENTS]() {
    ApiService.setHeader();
    ApiService.query("clients?sort=name")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CLIENTS, data.data);
      })
      .catch(({ response }) => {
        // TODO
      });
  }

  @Action
  [Actions.POST_CLIENT](client: Record<string, unknown>) {
    ApiService.setHeader();
    return ApiService.post("clients", client)
        .catch(({ response }) => {
          // TODO
        });
  }

  @Action
  [Actions.LOAD_ASOPROJECTS]() {
    ApiService.setHeader();
    ApiService.query("asoprojects?embed=client&sort=name")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ASOPROJECTS, data.data);
        })
        .catch(({ response }) => {
          // TODO
        });
  }

  @Action
  [Actions.POST_ASOPROJECT](asoproject: Record<string, unknown>) {
    ApiService.setHeader();
    return ApiService.post("asoprojects", asoproject)
        .catch(({ response }) => {
          // TODO
        });
  }

  @Action
  [Actions.POST_ASOREPORT](report: Record<string, unknown>) {
    ApiService.setHeader();
    return ApiService.post("asoprojects/report", report)
        .catch(({ response }) => {
          // TODO
        });
  }

  @Action
  [Actions.LOAD_UAPROJECTS]() {
    ApiService.setHeader();
    ApiService.query("uaprojects?embed=client&sort=name")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_UAPROJECTS, data.data);
        })
        .catch(({ response }) => {
          // TODO
        });
  }

  @Action
  [Actions.POST_UAPROJECT](uaproject: Record<string, unknown>) {
    ApiService.setHeader();
    return ApiService.post("uaprojects", uaproject)
        .catch(({ response }) => {
          // TODO
        });
  }

  @Action
  [Actions.POST_UAREPORT](report: Record<string, unknown>) {
    ApiService.setHeader();
    return ApiService.post("uaprojects/report", report)
        .catch(({ response }) => {
          // TODO
        });
  }

  @Action
  [Actions.LOAD_INTEGRATIONS]() {
    ApiService.setHeader();
    return ApiService.query("integrations?embed=thirdparty")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_INTEGRATIONS, data.data);
        })
        .catch(({ response }) => {
          // TODO
        });
  }

  @Action
  [Actions.POST_INTEGRATION](integration: Record<string, unknown>) {
    ApiService.setHeader();
    return ApiService.post("integrations", integration)
        .catch((error) => {
          return Promise.reject(error);
        });
  }

  @Action
  [Actions.LOAD_THIRDPARTIES]() {
    ApiService.setHeader();
    ApiService.query("thirdparties")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_THIRDPARTIES, data.data);
        })
        .catch(({ response }) => {
          // TODO
        });
  }

  @Action
  [Actions.LOAD_ROLES]() {
    ApiService.setHeader();
    ApiService.query("roles")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ROLES, data.data);
        })
        .catch(({ response }) => {
          // TODO
        });
  }

  @Action
  [Actions.LOAD_USERS]() {
    ApiService.setHeader();
    ApiService.query("users")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USERS, data.data);
        })
        .catch(({ response }) => {
          // TODO
        });
  }

  @Action
  [Actions.POST_USER](user: Record<string, unknown>) {
    ApiService.setHeader();
    return ApiService.post("users", user)
        .catch(({ response }) => {
          // TODO
        });
  }

}
